<script setup>
import {onMounted, ref, watch} from "vue";
import axios from "axios";

const banks = ref(null);
const account_name = ref("");
const account_number = ref("");
const bank_code = ref("");

const emit = defineEmits(["updatedAccountName", "updatedAccountNumber", "updatedBankCode"]);
const props = defineProps(["errors", "bankCode", "accountNumber", "accountName"]);

watch(account_name, function (newValue, oldValue) {
    emit("updatedAccountName", newValue);
});
watch(account_number, function (newValue, oldValue) {
    emit("updatedAccountNumber", newValue);
});
watch(bank_code, function (newValue, oldValue) {
    emit("updatedBankCode", newValue);
});

async function getBanks() {
    await axios.get("/apis/v4/saas/vfd/banks")
        .then(response => {
            banks.value = response.data.data;
        }).catch();
}

onMounted(function () {
    getBanks();

    bank_code.value = props.bankCode;
    account_name.value = props.accountName;
    account_number.value = props.accountNumber;
});

</script>

<template>
    <div>
        <div class="row g-3">
            <!--Label: Bank, Attributes:bank_code -->
            <div class="col-md-12">
                <div class="form-group">
                    <select type="text" class="form-control" id="bank_code" v-model="bank_code"
                            aria-describedby="bank_code-help"
                            :class="[errors?.bank_code ? 'is-invalid': '',!errors?.bank_code && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            required>
                        <option value="" selected> --- Select Bank ---</option>
                        <option v-for="b in banks" :value="b.code">{{ b.name }}</option>
                    </select>
                </div>
            </div>
            <!--Label: Account Name, Attributes:account_name -->
            <div class="col-md-12">
                <div class="form-group">
                    <input
                        type="text"
                        class="form-control"
                        id="account_name"
                        v-model="account_name"
                        aria-describedby="account_name-help"
                        :class="[errors.account_name ? 'is-invalid' : '',!errors.account_name && Object.keys(errors).length > 1? 'is-valid': '',]"
                        placeholder="Account Name"
                    />
                    <div class="invalid-feedback" v-if="errors.account_name">
                        {{ errors.account_name.toString() }}
                    </div>
                </div>
            </div>
            <!--Label: Account Number, Attributes:account_number -->
            <div class="col-md-12">
                <div class="form-group">
                    <input
                        type="text"
                        class="form-control"
                        id="account_number"
                        v-model="account_number"
                        aria-describedby="account_number-help"
                        :class="[errors.account_number ? 'is-invalid' : '',!errors.account_number && Object.keys(errors).length > 1? 'is-valid': '',]"
                        placeholder="Account Number"
                    />
                    <div class="invalid-feedback" v-if="errors.account_number">
                        {{ errors.account_number.toString() }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

