<template>
    <div>
        <form @submit.prevent="submitForm">
            <div class="row">
                <div class="alert alert-info" role="alert">
                    Please provide an account number to receive payments on individual transactions directly to you. (This is not an account for the market.)
                </div>
            </div>
            <div class="row gap-3">
                <div class="col-12">
                    <div class="form-floating">
                        {{}}
                        <select class="form-select" id="bankSelect" v-model="selectedBank">
                            <option v-for="bank in banks" :value="bank.id">{{ bank.name }}</option>
                        </select>
                        <label for="bankSelect">Select a Bank</label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-floating">
                        <input type="text" class="form-control" id="accountNumber" v-model="accountNumber">
                        <label for="accountNumber">Account Number</label>

                    </div>
                </div>
                <div class="col-12">
                    <div class="form-floating">
                        <input type="text" class="form-control" id="accountNumber" v-model="accountName">
                        <label for="accountNumber">Account Name</label>

                    </div>
                </div>
                <button type="submit" class="btn btn-primary">
                    Submit
                    <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                </button>
            </div>
        </form>
    </div>
</template>
<script setup>
import axios from 'axios';
import Swal from 'sweetalert2';
import {onMounted, ref} from "vue";

const props = defineProps(["user"]);
const banks = ref(['KCB', 'Equity', 'Cooperative', 'Diamond Trust', 'Stanbic', 'Barclays', 'Standard Chartered', 'CFC Stanbic', 'NIC', 'Commercial Bank of Africa']);
const selectedBank = ref(null);
const accountNumber = ref(null);
const accountName = ref(null);
const saving = ref(false);

onMounted(() => {
    accountName.value = props.user.account_name
    accountNumber.value = props.user.account_number
    selectedBank.value = props.user.bank_id
    getBanks();
})
const getBanks = () => {
    axios.get('/apis/v4/saas/vfd/banks').then((response) => [
       banks.value = response.data.data
    ]);
}
const submitForm = () => {
    saving.value = true;
    axios.post('/apis/v4/profile/bank', {
        bank: selectedBank.value,
        accountName: accountName.value,
        accountNumber: accountNumber.value
    })
        .then(() => {
            saving.value = false;
            Swal.fire('Success', 'Bank account added successfully', 'success');
            location.href = '/four/profile';

        })
        .catch(() => {
            saving.value = false;
            Swal.fire('Error', 'Something went wrong', 'error');
        });
}
</script>
